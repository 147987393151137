@-webkit-keyframes envirabox-rotate {
  from {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg); }
  to {
	-webkit-transform: rotate(359deg);
	transform: rotate(359deg); } }

@keyframes envirabox-rotate {
  from {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg); }
  to {
	-webkit-transform: rotate(359deg);
	transform: rotate(359deg); } }

/* Transition effects */
.envirabox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1); }

/* transitionEffect: slide */
.envirabox-fx-slide.envirabox-slide--previous {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  opacity: 0; }

.envirabox-fx-slide.envirabox-slide--next {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  opacity: 0; }

.envirabox-fx-slide.envirabox-slide--current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1; }

/* transitionEffect: fade */
.envirabox-fx-fade.envirabox-slide--previous,
.envirabox-fx-fade.envirabox-slide--next {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

.envirabox-fx-fade.envirabox-slide--current {
  opacity: 1; }

/* transitionEffect: zoom-in-out */
.envirabox-fx-zoom-in-out.envirabox-slide--previous {
  -webkit-transform: scale3d(1.5, 1.5, 1.5);
  transform: scale3d(1.5, 1.5, 1.5);
  opacity: 0; }

.envirabox-fx-zoom-in-out.envirabox-slide--next {
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0; }

.envirabox-fx-zoom-in-out.envirabox-slide--current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1; }

/* transitionEffect: rotate */
.envirabox-fx-rotate.envirabox-slide--previous {
  -webkit-transform: rotate(-360deg);
  -ms-transform: rotate(-360deg);
  transform: rotate(-360deg);
  opacity: 0; }

.envirabox-fx-rotate.envirabox-slide--next {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
  opacity: 0; }

.envirabox-fx-rotate.envirabox-slide--current {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  opacity: 1; }

/* transitionEffect: circular */
.envirabox-fx-circular.envirabox-slide--previous {
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  opacity: 0; }

.envirabox-fx-circular.envirabox-slide--next {
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  opacity: 0; }

.envirabox-fx-circular.envirabox-slide--current {
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1; }

/* transitionEffect: tube */
.envirabox-fx-tube.envirabox-slide--previous {
  -webkit-transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg); }

.envirabox-fx-tube.envirabox-slide--next {
  -webkit-transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg); }

.envirabox-fx-tube.envirabox-slide--current {
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1); }
